import { CheckIcon } from '@chakra-ui/icons';
import { Flex, Box, List, ListIcon, ListItem, SimpleGrid, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import * as React from 'react';

interface OpeningHour {
    weekday: string;
    time1: string;
    time2: string;
}

const openingHours: OpeningHour[] = [
    { weekday: 'Montag', time1: '8:00 - 13:00', time2: '14:00 - 18:00' },
    { weekday: 'Dienstag', time1: '8:00 - 13:00', time2: '14:00 - 17:00' },
    { weekday: 'Mittwoch', time1: '8:00 - 13:30', time2: '' },
    { weekday: 'Donnerstag', time1: '8:00 - 13:00', time2: '14:00 - 18:00' },
    { weekday: 'Freitag', time1: '8:00 - 13:30', time2: '' },
];

const range = (n: number) => Array.from({ length: n }, (value, key) => key);

export const OpeningHours: React.FC = () => {
    const now = new Date();
    const intWeekday = (now.getDay() + 7) % 7;

    return (
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={{ base: 3, md: 6 }} w={'100%'}>
            <Box w={'100%'} bg={'white'} p={2} boxShadow={'md'} borderRadius={'md'}>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th colSpan={2} textAlign={'center'} fontSize={'lg'} color='secondary.500'>
                                Öffnungszeiten
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {range(5).map((val) => (
                            <React.Fragment key={val}>
                                <Tr bg={val + 1 === intWeekday ? 'gray.200' : ''}>
                                    <Td fontWeight={val + 1 === intWeekday ? 700 : 400} pt={3} pb={0} borderBottom={0}>
                                        {openingHours[val].weekday}
                                    </Td>
                                    <Td fontWeight={val + 1 === intWeekday ? 700 : 400} textAlign='right' pt={3} pb={0} borderBottom={0}>
                                        {openingHours[val].time1}
                                    </Td>
                                </Tr>
                                <Tr bg={val + 1 === intWeekday ? 'gray.200' : ''}>
                                    <Td pt={0} pb={3} />
                                    <Td fontWeight={val + 1 === intWeekday ? 700 : 400} textAlign='right' pt={0} pb={3}>
                                        {openingHours[val].time2}
                                    </Td>
                                </Tr>
                            </React.Fragment>
                        ))}
                    </Tbody>
                </Table>
            </Box>
            <Flex w={'100%'} bg={'white'} p={4} boxShadow={'md'} borderRadius={'md'} justifyContent={'center'} alignItems={'center'}>
                <List spacing={3} textAlign={'justify'} style={{ WebkitHyphens: 'manual', msHyphens: 'manual', hyphens: 'manual' }}>
                    <ListItem>
                        <ListIcon as={CheckIcon} color={'primary.500'} />
                        Termine nach Vereinbarung
                    </ListItem>
                    <ListItem>
                        <ListIcon as={CheckIcon} color={'primary.500'} />
                        Privatpatienten und gesetzlich versicherte Patienten als Selbstzahler
                    </ListItem>
                    <ListItem>
                        <ListIcon as={CheckIcon} color={'primary.500'} />
                        Um Termin&shy;vereinbarung wird gebeten, da wir unsere Patienten nicht warten lassen möchten. Eine Akutbehandlung ist im
                        Notfall selbstverständlich möglich. In diesem Fall möchten wir Sie freundlich bitten, unsere Online-Termin&shy;buchung zu
                        nutzen oder Ihren Besuch telefonisch anzukündigen und etwas Wartezeit mitzubringen, da unsere Terminpatienten weiterhin
                        pünktlich behandelt werden müssen.
                    </ListItem>
                </List>
            </Flex>
        </SimpleGrid>
    );
};
