import { Box, Heading, keyframes } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React from 'react';
import './page404.style.scss';

const animationKeyframes = keyframes`
    0% { font-size: 100px; color: #566573; }
    50% { font-size: 200px; color: #273746; }
    100% { font-size: 250px; color: #17202a; }
`;

const animation = `${animationKeyframes} 5s infinite`;

export const Page404: React.FC = () => {
    return (
        <Box bg='#aeb6bf' w='100vw' h='100vh' p={8}>
            <Heading as={motion.h1} animation={animation} textAlign={'center'} fontFamily={'Alfa Slab One'} h='35%'>
                404
            </Heading>
            <Heading as='h3' fontFamily={'Josefin Slab'} textAlign={'center'} color={'white'}>
                Die Seite konnte nicht gefunden werden.
            </Heading>
        </Box>
    );
};
