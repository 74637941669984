import * as React from 'react';
import { Flex, Image, ListItem, OrderedList, Stack, Text, UnorderedList } from '@chakra-ui/react';
import Huber1 from 'images/leistungen/Huber360_Evolution_Wood_1.jpg';
import Huber2 from 'images/leistungen/Huber360_Evolution_Wood_2.jpg';

export const Huber360Item: React.FC = (): JSX.Element => (
    <Stack textAlign={'justify'}>
        <Text>
            Der HUBER® 360 Evolution wurde für die neuromuskuläre, also die Nerven und Muskeln betreffende, Therapie entwickelt. Er schlägt die
            Brücke zwischen gesundheitlicher Therapie und Training. Das Kürzel "HUBER" steht für HUMAN BODY EQUILIBRIUM und meint Balance und
            Gleichgewicht. Sensoren in der neuartigen und mehrsprachig motorisierten Plattform registrieren die Bewegungen der Patienten. Die Sensoren
            in den Griffen messen die Kraft. Damit ermöglicht der HUBER® 360 Evolution eine individuell abgestimmte, neuromuskuläre Analyse zu Beginn
            der Therapie. Darauf basierend kann dann Ihr eigentliches Training ausgerichtet werden, um postoperative, akute oder chronische Defizite
            der Muskulatur auszugleichen.
        </Text>
        <Flex w={'full'}>
            <Image src={Huber2} alt='Huber2' w={{ base: '100%', md: '50%' }} />
            <Image src={Huber1} alt='Huber1' w={'50%'} display={{ base: 'none', md: 'block' }} />
        </Flex>
        <Text>
            Unbeeinflusst davon, ob Sie z. B. Rücken-, Knie- oder Schulterbeschwerden haben, trainieren Sie mittels spezieller Trainingsprogramme
            natürliche Bewegungen, die an Ihren Alltag angelehnt sind. Die Behandlung mit dem HUBER® 360 Evolution ist in vier Kategorien gegliedert:
        </Text>
        <OrderedList>
            <ListItem>Flexibilität und Mobilität</ListItem>
            <ListItem>Dynamische Kräftigung</ListItem>
            <ListItem>Körperhaltung und Gleichgewicht</ListItem>
            <ListItem>Ausdauer</ListItem>
        </OrderedList>
        <Text>Bei den folgenden Indikationen wird ein Training mit dem HUBER® 360 Evolution empfohlen:</Text>
        <UnorderedList>
            <ListItem>Verstauchungen und Brüche</ListItem>
            <ListItem>Hexenschuss, Ischias-Schmerz, Bandscheibenvorfall</ListItem>
            <ListItem>Muskuläre Haltungsschwäche</ListItem>
            <ListItem>Skoliose</ListItem>
            <ListItem>Ruptur der Achillessehne</ListItem>
            <ListItem>Verletzungen der Bänder am Sprunggelenk</ListItem>
            <ListItem>Hüftgelenkersatz (Hüft-TEP) / Kniegelenkersatz (Knie-TEP)</ListItem>
            <ListItem>Chronische und akute Rückenschmerzen</ListItem>
            <ListItem>Schmerzen im Schulter- oder Ellenbogengelenk</ListItem>
            <ListItem>Dysbalancen der Rumpf- oder Bauchmuskulatur oder Muskelschwäche</ListItem>
            <ListItem>Schwäche der Beckenbodenmuskulatur (z. B. nach Schwangerschaft)</ListItem>
            <ListItem>Beinachsen-Instabilität</ListItem>
            <ListItem>Gelenkinstabilität</ListItem>
            <ListItem>Diabetes</ListItem>
            <ListItem>Fibromyalgie (Weichteilrheuma)</ListItem>
            <ListItem>Parkinson</ListItem>
            <ListItem>Multiple Sklerose</ListItem>
            <ListItem>Osteoporose</ListItem>
            <ListItem>Sturzprophylaxe</ListItem>
            <ListItem>Schwindel</ListItem>
            <ListItem>Zustand nach Schlaganfall</ListItem>
            <ListItem>Zustand nach Wirbelsäulen-OP</ListItem>
            <ListItem>Zustand nach Meniskus- oder Kreuzband-OP</ListItem>
            <ListItem>Prähabilitation vor einer OP (neuromuskuläre Optimierung bereits vor dem Eingriff)</ListItem>
            <ListItem>Postoperative Rehabilitation (zur Optimierung des Ergebnisses nach einer OP)</ListItem>
            <ListItem>Wettkampfvorbereitung</ListItem>
            <ListItem>Sportarten-spezifisches Training (z. B. zur Vorbereitung auf die Ski-Saison)</ListItem>
        </UnorderedList>
        <Image src={Huber1} alt='Huber1' display={{ base: 'block', md: 'none' }} />
        <Text>
            Je nach Krankheitsbild können weitere sowohl akute als auch chronische muskuläre und/oder neurologische Beschwerden mit dem HUBER® 360
            Evolution therapiert werden:
        </Text>
        <OrderedList>
            <ListItem>Flexibilität und Mobilität</ListItem>
            <ListItem>Dynamische Kräftigung</ListItem>
            <ListItem>Körperhaltung und Gleichgewicht</ListItem>
            <ListItem>Ausdauer</ListItem>
        </OrderedList>
        <Text>
            Zu Beginn und als Basis der Therapie mit dem HUBER® 360 Evolution wird in der Regel eine neuromuskuläre Analyse der Dysbalancen
            durchgeführt. Auf der Grundlage der bei der Analyse erhobenen Daten und der Diagnose des Arztes erfolgt eine Serie von in der Regel 15
            Trainingsbehandlungen. Meistens werden diese im Abstand von 3 bis 7 Tagen durchgeführt. Abschließend wird eine erneute neuromuskuläre
            Analyse empfohlen. Diese dient der Dokumentation und Erfolgskontrolle der Therapie. Das Trainingsprinzip, die Ziele, der Ablauf inklusive
            Eingangs- und Abschlusskontrollen entsprechen den Vorgaben einer medizinischen Trainings- oder Kräftigungstherapie (MTT/MKT).
        </Text>
        <Text>Alle Vorteile auf einen Blick:</Text>
        <OrderedList>
            <ListItem>Individuelle Anpassung der Therapie auf Ihre Bedürfnisse</ListItem>
            <ListItem>Schnelles, effektives und nachhaltiges Therapiekonzept</ListItem>
            <ListItem>Für alle Altersgruppen geeignet</ListItem>
            <ListItem>Spielerische Verbesserung von Mobilität, Stabilität, Ausdauer und Gleichgewicht</ListItem>
            <ListItem>Schmerzfreies Training unter medizinischer Supervision</ListItem>
        </OrderedList>
    </Stack>
);
